import { baseURL } from 'components/config';
import { Link } from "react-router-dom";
import TabLeft from "./TabLeft";
import ReactHtmlParser from 'react-html-parser';
import React, { useState, useEffect } from 'react';

const languageCode = sessionStorage.getItem('languageCode');
const request_content_url = `${baseURL}/js/request/home?language=${languageCode}`;

const UseCases = () => {
  const [activeIndex, setActiveIndex] = useState('');
  const [switches, setSwitches] = useState([]);
  const [switchTitle, setSwitchTitle] = useState({});

  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  const getUseCaseTab = (switches) => {
    if (activeIndex === '' && switches.length > 0) {
      setActiveIndex(switches[Object.keys(switches)[0]].id);
    }

    return (
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {Object.keys(switches).map(key => (
          <li key={`uctab-${switches[key].id}`} className="nav-item" onClick={() => handleOnClick(switches[key].id)}>
            <button
              className={
                activeIndex === switches[key].id ? "nav-link active" : "nav-link"
              }
            >
              {switches[key].title}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const getUseCaseTabContent = (switches) => {
    return (
      <div className="tab-content" id="myTabContent">
        {Object.keys(switches).map(key => (
          <div
            key={`uctab-content-${switches[key].id}`}  // Use backticks for string interpolation
            className={
              activeIndex === switches[key].id
                ? "tab-pane fade show active"
                : "tab-pane fade"
            }
          >
            <div className="cases-details-wrap">
              <div className="cases-details-left">
                <p>{switches[key].title}</p>
                {switches[key].content}
              </div>

              <div className="cases-details-content">
                <div className="icon">
                  <i className={switches[key].icon} />
                </div>

                <p>
                  {switches[key].content_2}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    fetch(request_content_url)
      .then((response) => response.json())
      .then((data) => {
        setSwitches(data.switch);
        setSwitchTitle(data.switch_title[0]);

        if (data.switch.length > 0) {
          setActiveIndex(data.switch[Object.keys(data.switch)[0]].id);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <section className="use-cases-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="use-cases-content">
              <div className="section-title mb-25">
                <h2
                  className="title title-animation wow fadeInLeft"
                  data-wow-delay=".2s"
                >
                  {ReactHtmlParser(switchTitle.title)}
                </h2>
              </div>
              <p className="new-text">
                {switchTitle.content}
              </p>
            </div>
          </div>
          <div className="col-lg-7" key='usecases-col'>
            <div className="use-cases-nav-wrap">
              {getUseCaseTab(switches)}
              {getUseCaseTabContent(switches)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
