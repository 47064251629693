import { Route, Routes } from "react-router-dom";
import routes from "./pages/index";
import { useEffect, useState } from "react";
import AOS from "aos";
import Loading from "./Loading"; // Import the Loading component
import Greeting from './components/layout/pop_ups/Greeting'; // Import the Greeting component


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <Greeting /> {/* Add the Greeting component here */}
          <Routes>
            {routes.map((data, index) => (
              <Route
                exact={true}
                path={data.path}
                element={data.component}
                key={index}
              />
            ))}

            {/* <Route path='*' element={<Page404 />} /> */}
          </Routes>
        </>
      ) : (
        <Loading /> // Use the Loading component here
      )}
    </>
  );
}

export default App;
