import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { baseURL } from 'components/config';
import VideoPopup from "components/video/VideoPopup";
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'global_providers/TranslationProvider'; // Adjust the import path

const icon_right_padding = {
    paddingRight: '10px', // Adjust width as needed
};

// Styles specifically for desktop devices
const desktopButtonCss = {
    width: '200px',
    height: '60px',
    fontSize: '16px',
};

const Voices = () => {
    const { translations, getTranslatedWords } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(43); // Default active index
    const [descriptions, setDescriptions] = useState([]);
    const [descriptionTitle, setDescriptionTitle] = useState('');

    const languageCode = sessionStorage.getItem('languageCode');
    const request_description_url = `${baseURL}/js/request/home?language=${languageCode}`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(request_description_url);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setDescriptions(data.timeline || []);
                setDescriptionTitle(data.timeline_title[0] || '');
                if (data.timeline.length > 0) {
                    setActiveIndex(data.timeline[0].id); // Set the first item as active
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        };

        fetchData();
    }, [request_description_url]);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    };

    const getShortDescriptions = (models, activeIndex) => {
        if (!models || models.length === 0) {
            return null;
        }

        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9 col-lg-10">
                        <div className="section-title text-center mb-80">
                            <h2 className="title">
                                {getTranslatedWords(translations, 'voice_title')}
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="vioces-tab-wrap">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {models.map(model => (
                                    <li className="nav-item" key={model.id} onClick={() => handleOnClick(model.id)}>
                                        <button
                                            style={{
                                                ...icon_right_padding,
                                                ...(window.innerWidth >= 1024 ? desktopButtonCss : {})
                                            }}
                                            className={activeIndex === model.id ? "nav-link active" : "nav-link"}
                                        >
                                            <span className="icon" style={icon_right_padding}>
                                                <i className={`${model.icon} fa-lg`} />
                                            </span>
                                            {model.title}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                {models.map(model => (
                                    <div
                                        key={model.id}
                                        className={`tab-pane fade ${activeIndex === model.id ? "show active" : ""}`}
                                    >
                                        <div className="row justify-content-center">
                                            <div className="col-lg-9 col-md-6 col-sm-10">
                                                <div className="vioces-item">
                                                    <div className="vioces-thumb">
                                                        <img
                                                            src={model.cover_photo_path || ''}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="vioces-content">
                                                        <div className="play-btn popup-video">
                                                            <i className={`${model.icon} fa-lg`} />
                                                        </div>
                                                        <h2 className="title">{model.title}</h2>
                                                        <p>
                                                            {ReactHtmlParser(model.content)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>{/* End of tab content */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <section className="">
            {getShortDescriptions(descriptions, activeIndex)}
        </section>
    );
};

export default Voices;
