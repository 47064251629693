import { baseURL } from 'components/config';
import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

const brandPadding = {
   paddingTop: '3%', // Adjust width as needed
};

const brandLogoCss = {
   height: '85px',
   width: '120px', // Adjust width as needed
};

const settings1 = {
/*  speed: 7000,
  autoplay: true,
  autoplaySpeed: 0,*/
  centerMode: true,
  cssEase: "linear",
  slidesToShow: 1,
 /* slidesToScroll: 1,*/
  variableWidth: true,
/*  infinite: true,
  initialSlide: 1,*/
  arrows: false,
  buttons: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {},
    },
    {
      breakpoint: 992,
      settings: {},
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
const settings2 = {
 /*  speed: 7000,
  autoplay: true,
  autoplaySpeed: 0,*/
  centerMode: true,
  cssEase: "linear",
  slidesToShow: 1,
 /* slidesToScroll: 1,*/
  variableWidth: true,
/*  infinite: true,
  initialSlide: 1,*/
  arrows: false,
  buttons: false,
  rtl: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {},
    },
    {
      breakpoint: 992,
      settings: {},
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};


const Brand1 = () => {
  const [partnerships, setPartnerships] = useState([]);

  useEffect(() => {
    const languageCode = sessionStorage.getItem('languageCode');
    const partnershipUrl  = baseURL + '/js/item-partners?language=' + languageCode;

    fetch(partnershipUrl)
      .then(response => {
        ////console.log(partnershipUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setPartnerships(data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const getPartnerships = data => {
    if (!data || data.length === 0) {
      return null;
    }

    return (
      <div className="container-fluid g-0">
        <div className="brand-item-wrap">
          <h4 className="title">{ReactHtmlParser(partnerships.title)}</h4>

          <Slider {...settings1} className="brand-active-two">
            {Object.values(data.partners[0]).map(partnership => (
              <div className="brand-item-two" key={partnership.id}>
                <img style={brandLogoCss} src={partnership.cover_photo_path} alt="" />
              </div>
            ))}
          </Slider>

          <Slider {...settings2} className="brand-active-three" dir={sessionStorage.getItem('languageCode') === 'rtl' ? 'rtl' : 'ltr'}>
            {Object.values(data.partners[1]).map(partnership => (
              <div className="brand-item-two" key={partnership.id}>
                <img style={brandLogoCss} src={partnership.cover_photo_path} alt="" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  };

  return (
    <div className="brand-area-two pb-100" style={brandPadding}>
      {getPartnerships(partnerships)}
    </div>
  );
};

export default Brand1;
