// index.js

// Suppress specific warnings (not recommended for production)
//import './suppressWarnings'; // Import suppression file before other imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import AppWrapper from './AppWrapper'; 


/*const originalWarn = console.warn;
console.warn = (...args) => {
  if (!args[0].includes('Warning: Each child in a list should have a unique "key" prop.')) {
    originalWarn(...args);
  }
};*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <AppWrapper />
  </BrowserRouter>,
);
