import React, { useState, useEffect } from "react";
import { baseURL } from 'components/config';
import ReactHtmlParser from 'react-html-parser';

const about_margin_bottom = {
    marginBottom: '12px', // Adjust width as needed
};

const section_margin_bottom = {
    marginBottom: '5%', // Adjust width as needed
};



export default function About1() {
    const [activeIndex, setActiveIndex] = useState([]);
    const handleOnClick = (index) => {
        setActiveIndex(index);
    };

    //ky code
    const languageCode = sessionStorage.getItem('languageCode');
    const request_description_url = `${baseURL}/js/request/home?language=${languageCode}`;
    const [descriptions, setDescriptions] = useState([]);
    const [descriptionTitle, setDescriptionTitle] = useState([]);

    
    useEffect(() => {
        fetch(request_description_url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setDescriptions(data.timeline);
                setDescriptionTitle(data.timeline_title[0]);
            })
            .catch((err) => {
                console.error('Fetch error:', err);
            });
    }, [request_description_url, setDescriptions]);

    const getShortDescriptions = (models, activeIndex, handleOnClick) => {
        let content = [];
        if(models){

                if(activeIndex == '')
                {
                   if(models.length > 0){
                    setActiveIndex(models[Object.keys(models)[0]].id);
                    //console.log('Current :' + activeIndex);
                    //console.log(models[Object.keys(models)[0]].id);
                    
                   }
                }
              
                content.push(
                  <div className="container" key="container">
                      <div className="row">
                          <div className="col-lg-12">
                              <div className="about-wrap">

                                  <ul className="nav nav-tabs" id="myTab" role="tablist" style={about_margin_bottom}>
                                      {models && Object.keys(models).map(key => (
                                          <li className="nav-item" key={key} onClick={() => handleOnClick(models[key].id)}>
                                              <button
                                                  className={
                                                      activeIndex === models[key].id ? "nav-link active" : "nav-link"
                                                  }
                                              >
                                                  <span className="icon">
                                                      <i className={models[key].icon + " fa-lg"} />
                                                  </span>{" "}
                                                  {models[key].title}
                                              </button>
                                          </li>
                                      ))}
                                  </ul>

                                  <div className="tab-content" id="myTabContent">
                                      {models && Object.keys(models).map(key => (
                                          <div
                                              key={key}
                                              className={
                                                  activeIndex === models[key].id
                                                      ? "tab-pane fade show active"
                                                      : "tab-pane fade"
                                              }
                                          >
                                              <div className="row">
                                                  <div className="col-lg-7 order-0 order-lg-2">
                                                      <div
                                                          className="about-img wow fadeInRight"
                                                          data-wow-delay=".2s"
                                                      >
                                                          <img src={models[key].cover_photo_path || ''} alt="" />

                                                      </div>
                                                  </div>
                                                  <div className="col-lg-5">
                                                      <div className="about-content">
                                                          <div className="section-title-two mb-60">
                                                              <h2
                                                                  className="title wow fadeInLeft"
                                                                  data-wow-delay=".2s"
                                                              >
                                                                  {models[key].title}
                                                              </h2>
                                                          </div>
                                                          <p>
                                                               {ReactHtmlParser(models[key].content)}
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              );
        }
        

        return content;
    };

    return (
        <section className="" style={section_margin_bottom}>
            {getShortDescriptions(descriptions, activeIndex, handleOnClick)}
            <div className="about-shape">
                {/*  <img
              src="/assets/img/images/about_shape.png"
              alt=""
              className="alltuchtopdown"
            />*/}
            </div>
        </section>
    );
}
