// AppWrapper.js
import React, { useState, useEffect } from 'react';
import App from './App';
import Loading from './Loading'; // Loading component
import { TranslationProvider } from './global_providers/TranslationProvider'; // Adjust the import path
import { baseURL } from './components/config'; // Adjust the import path for your config file

const AppWrapper = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Replace this with the actual API call
        const response = await fetch(baseURL + '/js/home-data'); 
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        // Process the fetched data here, if necessary

      } catch (error) {
        //console.error('Error fetching initial data:', error);
        //setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message
  }

  return (
    <TranslationProvider baseURL={baseURL}>
      <App />
    </TranslationProvider>
  );
};

export default AppWrapper;
