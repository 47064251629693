import { baseURL } from 'components/config';
import Slider from "react-slick";
//rest api
import React, { useState, useEffect } from 'react';
const home_data_url = baseURL + '/js/home-data';
const brand_logo_css = {
   width: '200px', // Adjust width as needed
};
const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  arrows: false,
  slidesToShow: 7,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const Brand2 = () => {

  //rest api
  const [home_data, setHomeData] = useState([]);
  const [partnerships, setpartnerships] = useState([]);
  useEffect(() => {
    fetch(home_data_url)
       .then((response) => response.json())
       .then((data) => {
          setHomeData(data);
          setpartnerships(data.partnerships);
       })
       .catch((err) => {
          //console.log(err.message);
       });
  }, []);

  return (
    <section className="brand-aera-three">
      <div className="container-fluid p-0">
        <div className="brand-wrap-three">
          <h6 className="title">Trusted by 100+ marketing teams</h6>
          <Slider {...settings} className="row g-0 brand-active-four">
            
                {partnerships.map((partnership) => {
                   return (
                      <div className="brand-item-three">
                        <img src={partnership.cover_photo_path} alt={partnership.name}  style={brand_logo_css} />
                      </div>
                 );
                })}

          
          </Slider>
        </div>
      </div>
    </section>
  );
};
export default Brand2;
