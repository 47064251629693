import { baseURL } from 'components/config';
import Header4 from "components/header/Header4"
import DataBg from "components/layout/DataBg"
//rest api
import { useCallback, useEffect, useState } from "react";
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path
import { useLocation } from 'react-router-dom';

const company_url = baseURL + '/js/company';

export default function Login() { 
    const location = useLocation();
    const inquiry_url = baseURL + '/js/customer-inquiry' ;
    const urlParams = new URLSearchParams(location.search);
    const inquiryType = urlParams.get('inquiry_type');
    const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
    useEffect(() => {
        // Set zoom to 100% for the login page
        document.getElementById('root').style.zoom = '100%';

        // Clean up function to reset zoom when the component unmounts
        return () => {
          document.getElementById('root').style.zoom = '80%';
        };
      }, []);

    useEffect(() => {
            const form = document.getElementById('contactForm');
            form.addEventListener('submit', function(event) {
                  event.preventDefault(); // Prevent the default form submission

                  // Collect form data
                  const name = document.getElementById('name').value;
                  const email = document.getElementById('email').value;
                  const phone = document.getElementById('phone').value;
                  const message = document.getElementById('message').value;

                  // Prepare data to be sent
                  const data = {
                    name: name,
                    email: email,
                    phone: phone,
                    message: message,
                    inquiry_type: inquiryType // Include inquiry_type in the data
                  };

                  // Send data to the API
                  fetch(inquiry_url, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                  })
                  .then(response => response.json())
                  .then(data => {
                    ////console.log('Success:', data);
                    alert('Your inquiry has been submitted successfully.');
                  })
                  .catch((error) => {
                    console.error('Error:', error);
                    alert('There was an error submitting your inquiry.');
                  });
            });
    }, []);

    return (
        <>
            <DataBg />
            <Header4 />
            <main className="main-content">
                <div className="noise-bg" data-background="/assets/img/bg/noise_bg.png" />
                <div className="main-shape" data-background="/assets/img/images/main_shape.png" />
                <section className="login-area">
                    <div className="container">
                        <div className="row justify-content-left">
                            <div className="login-content">
                                    <div className="col-lg-6">

                                      <div className="contact-form">
                                                 <h3 className="title">{ getTranslatedWords( translations , 'contact_tel_desc') }</h3>
                                                   
                                        <form id="contactForm" action="#">
                                          <div className="form-grp">
                                            <input
                                              type="text"
                                              id="name"
                                              placeholder={ getTranslatedWords( translations , 'contact_form_name') } 
                                              required
                                            />
                                          </div>
                                          <div className="form-grp">
                                            <input
                                              type="email"
                                              id="email"
                                              placeholder={ getTranslatedWords( translations , 'contact_form_email') } 
                                              required
                                            />
                                          </div>
                                          <div className="form-grp">
                                            <input
                                              type="text"
                                              id="phone"
                                              placeholder={ getTranslatedWords( translations , 'contact_form_tel') } 
                                              required
                                            />
                                          </div>
                                          <div className="form-grp">
                                            <textarea
                                              name="message"
                                              id="message"
                                              placeholder={ getTranslatedWords( translations , 'contact_form_description') }
                                            />
                                          </div>
                                          <button className="btn" type="submit">
                                            { getTranslatedWords( translations , 'contact_form_submit') }
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                            </div>
                     

                            <div className="col-lg-6">
                                <div className="login-right-content-wrap">
                                    <div className="login-right-bg" data-background="assets/img/bg/error_bg.jpg" />
                                    <div className="login-right-content-inner">
                                        <img src="assets/img/images/login_img.png" alt="" />
                                        <h4 className="title">{ getTranslatedWords( translations , 'home_page_title') }</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}