import { useEffect, useState } from "react"
import { Link } from "react-router-dom";

const floating_contact_css = {
    /* Styles for the floating button */
    position: 'fixed',
    bottom: '11.5%', /* Adjust as needed */
    right: '31px', /* Adjust as needed */
    padding: '10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '50%', /* Making it round */
    width: '62px', /* Adjust width and height as needed */
    height: '62px', /* Adjust width and height as needed */
    cursor: 'pointer',
    zIndex: '999', /* Ensure it stays on top of other content */
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '30px'
};

const GoTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
    <>
        {/* New button */}
        <Link style={floating_contact_css} to='/login'>
            <i className="fa-solid fa-headset" />
        </Link>

        {/* Existing component */}
        <div className="existing-component">
            {isVisible && (
                <Link className="scroll-top scroll-to-target open" to='#' onClick={scrollToTop}>
                    <i className="fas fa-angle-up"></i>
                </Link>
            )}
        </div>
    </>
)
}
export default GoTop