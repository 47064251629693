import { baseURL } from 'components/config';
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

const languageCode = sessionStorage.getItem('languageCode');
const request_content_url = baseURL + '/js/request/home?language=' + languageCode;

const Roadmap = () => {

  const [timelines, setTimelines] = useState([]);
  const [timeline_title, setTimelineTitle] = useState([]);
  useEffect(() => {
      fetch(request_content_url)
         .then((response) => response.json())
         .then((data) => {
            setTimelines(data.timeline);
            setTimelineTitle(data.timeline_title[0]);
         })
         .catch((err) => {
            //console.log(err.message);
         });
  }, []);

  const getTimelines = timelines => {
      let content = [];
      content.push(
          <div className="row justify-content-center" key="timeline-wrapper">
            <div className="col-lg-12 col-md-9">
              <div className="roadmap-timeline-wrap">
                <div className="roadmap-line" data-background="assets/img/brand/Line.svg" />
                <ul className="list-wrap">
                  {Object.keys(timelines).map(key => {
                    const t_position = timelines[key].index % 2 === 1 ? 'fadeInRight' : 'fadeInLeft';
                    return (
                      <li key={key}>
                        <div className={`roadmap-item ${t_position}`}>
                          <div className="roadmap-img wow fadeIn" data-wow-delay=".2s">
                            <img src="/assets/img/images/roadmap_img01.png" alt="" />
                            <span className="number">{timelines[key].index}</span>
                          </div>
                          <div className="roadmap-content wow fadeIn" data-wow-delay=".2s">
                            <h4 className="title">{timelines[key].title}</h4>
                            <p className="new-text">{timelines[key].content}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        );

    return content;
  };
  
  return (
    <section className="roadmap-area pt-140 pb-130">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center mb-70">
              <h2
                className="title title-animation wow fadeInUp"
                data-wow-delay=".2s"
              >
                {ReactHtmlParser(timeline_title.title)}
              </h2>
            </div>
          </div>
        </div>

        { getTimelines(timelines) }
        </div>
    </section>
        
  );
};
export default Roadmap;
