import { baseURL } from 'components/config';
import Layout from "components/layout/Layout";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Collapse } from "react-collapse";
import ReactHtmlParser from 'react-html-parser';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path

//rest api
import React, { useEffect } from 'react';
const languageCode = sessionStorage.getItem('languageCode');
const faq_url = baseURL + '/js/faqs?language=' + languageCode;
const company_url = baseURL + '/js/company';
const icon_css = {
       paddingRight: '10px', // Adjust width as needed 
    };

const itemContent = {
  paddingTop: '5px',
  paddingBottom: '10px',
}
const containerStyles = {
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
}
const itemStyles = {
  flex: '1 0 100px', // Flexbox properties for each item
  margin: '5px', // Add spacing between items
  textAlign: 'center', // Optional: Align text in the center
  padding: '10px', // Optional: Add padding
};


export default function Help() {
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const [activeIndex, setActiveIndex] = useState([]);
  const handleOnClick = (index) => {
  
    setActiveIndex(index);
  };
  const [isActive, setIsActive] = useState({
    status: false,
    key: 2,
  });

  const handleToggle = (key) => {
    if (isActive.key === key) {
      setIsActive({
        status: false,
      });
    } else {
      setIsActive({
        status: true,
        key,
      });
    }
  };

  //rest api
  const [company, setCompany] = useState([]);
  const [categories, setCategories] = useState([]);
  const [faqSets, setFAQs] = useState([]);
  useEffect(() => {
    fetch(faq_url)
       .then((response) => response.json())
       .then((data) => {
          //console.log(faq_url);
          setFAQs(data.faqs);
          setCategories(data.categories);
          if(data.categories.length > 0){
            ////console.log('Set category  :' +categories[Object.keys(data.categories)[0]].id );
            setActiveIndex(categories[Object.keys(data.categories)[0]].id);
          }else{
            ////console.log('Not be set');
          }
       })
       .catch((err) => {
          ////console.log(err.message);
       });
  }, []);

   useEffect(() => {
      fetch(company_url)
         .then((response) => response.json())
         .then((data) => {
            ////console.log(data);
            setCompany(data);
         })
         .catch((err) => {
            ////console.log(err.message);
         });
   }, []);

                      
 const getLinkList = items => {
  let content = [];

  if (items) {
    items.forEach((item, index) => {
      content.push(
        <span key={item}>
          <Link to={`tel:${item}`}>{item}</Link>
          {index !== items.length - 1 && <br />} {/* Add <br> except for the last item */}
        </span>
      );
    });
  }

  return content;
};

const getCategoryMenus = categories => {
  let content = [];
  ////console.log('Generate category');
  ////console.log(categories);
  ////console.log('Active index :' + activeIndex);
  if(activeIndex == '')
  {
     if(categories.length > 0){
      setActiveIndex(categories[Object.keys(categories)[0]].id);
      
     }
  }
  
  content.push(
      <ul className="nav nav-tabs new-bgc3" style={containerStyles} id="myTab" role="tablist">
        {Object.keys(categories).map(key => (
              <li className="nav-item" style={itemStyles} onClick={() => handleOnClick(categories[key].id)}>
                <button style={itemContent}
                  className={
                    activeIndex == categories[key].id ? "nav-link active" : "nav-link"
                  } 
                >

                  <div className="icon white-text">
                        <i style={icon_css} className={categories[key].icon}>  </i> {categories[key].title} 
                  </div>
                      
                </button>
              </li>
         ))}
      </ul>
  );

  return content;
};


const getFAQListing = faqSets => {
  let content = [];
  ////console.log('Create listing :');
  ////console.log(faqSets);
  content.push(
    <div className="tab-content" id="myTabContent">
      {Object.keys(faqSets).map(key => (
        <div key={key}
              className={
                activeIndex == key
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
            >             
           <div className="faq-wrap">
                    <div className="accordion">
                       {Object.keys(faqSets[key]).map(child_key => (
                        <div key={faqSets[key][child_key].id}
                          className={
                            isActive.key === faqSets[key][child_key].id
                              ? "accordion-item active"
                              : "accordion-item"
                          }
                          key={faqSets[key][child_key].id}
                        >
                          <h2 className="accordion-header">
                            <button
                              className={
                                isActive.key === faqSets[key][child_key].id
                                  ? "accordion-button"
                                  : "accordion-button collapsed"
                              }
                              type="button"
                              onClick={() => handleToggle(faqSets[key][child_key].id)}
                            >
                              {faqSets[key][child_key].title}
                            </button>
                          </h2>
                          <Collapse isOpened={isActive.key === faqSets[key][child_key].id}>
                            <div
                              className={
                                isActive.key === faqSets[key][child_key].id
                                  ? "accordion-collapse collapse show"
                                  : "accordion-collapse collapse"
                              }
                            >
                              <div className="accordion-body">
                                <p className="new-text">{ReactHtmlParser(faqSets[key][child_key].content)}</p>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      ))}
                    </div>
                  </div>
               
        </div>

      ))}
    </div>
  );

  return content;
};




  return (
    <>
      <Layout
        headerStyle={1}
        footerStyle={1}
        breadcrumbTitle={
          <>
            { getTranslatedWords( translations , 'help_title') }
          </>
        }
      >
        <div>
          {/* faq-area */}
          <section className="faq-area-two pb-130">
            <div className="container">
              {/*<div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="problem-search-wrap text-center">
                    <form action="#">
                      <div className="form-grp">
                        <label htmlFor="search">
                          <i className="far fa-search" />
                        </label>
                        <input
                          type="text"
                          id="search"
                          placeholder="Write a question or problem"
                        />
                        <button type="submit" className="gradient-btn">
                          search
                        </button>
                      </div>
                      <span>
                        Or choose a category to quickly find the help you need
                      </span>
                    </form>
                  </div>
                </div>
              </div>*/}
              <div className="row">
                <div className="col-lg-12">
                  <div className="faq-tab-wrap">                  
                    {getCategoryMenus(categories)}
                    {getFAQListing(faqSets)}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* faq-area-end */}
          {/* help-area */}
          <section className="help-area pb-110">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="help-title text-center mb-75">
                    <h2 className="title">
                      { getTranslatedWords( translations , 'help_more_question') } 
                    </h2>
                    <p className="new-text">
                      { getTranslatedWords( translations , 'help_contact_us') } 
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-9 col-lg-10">
                  <div className="help-center-wrap">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="help-center-item">
                          <div className="icon">
                            <img src="assets/img/icon/help_icon01.svg" alt="" />
                          </div>
                          <div className="content">       
                            {getLinkList(company.tel)}
                            <p className="new-text"> { getTranslatedWords( translations , 'help_tel_description') } </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="help-center-item">
                          <div className="icon">
                            <img src="assets/img/icon/help_icon02.svg" alt="" />
                          </div>
                          <div className="content">
                            {getLinkList(company.email)}
                            <p className="new-text"> { getTranslatedWords( translations , 'help_email_description') } </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* help-area-end */}
        </div>
      </Layout>
    </>
  );
}
