import { baseURL } from 'components/config';
import Layout from "components/layout/Layout";
import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

const languageCode = sessionStorage.getItem('languageCode');
const request_content_url = baseURL + '/js/request/how it works?language=' + languageCode;

const Work = () => {

  const [steps, setSteps] = useState([]);
  const [step_title, setStepTitle] = useState([]);

   useEffect(() => {
      fetch(request_content_url)
         .then((response) => response.json())
         .then((data) => {
            //console.log(request_content_url);
            setSteps(data.step);
            //console.log(data.step);
            setStepTitle(data.step_title[0]);
         })
         .catch((err) => {
            //console.log(err.message);
         });
   }, []);

   const getSteps = steps => {
      let content = [];
      //console.log(steps);
      content.push(
        <div className="row">
          <div className="col-lg-12">
            {Object.keys(steps).map(key => (
              <div
                className="work-item"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                key={key} // Add a unique key for each element in the array
              >
                {/* Your content here */}

                <div className="work-thumb">
                    <img src={steps[key].cover_photo_path} alt="" />
                  </div>
                  <div className="work-content">
                    <span>Step { steps[key].index }</span>
                    <h2 className="title">{ steps[key].title }</h2>
                    <p className="new-text">
                      { steps[key].content }{" "}
                    </p>
                  </div>
        
              </div>
            ))}
          </div>
        </div>
      );

      return content;
  };


  return (
    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={
        <>
          {" "}
          {ReactHtmlParser(step_title.title)}
        </>
      }
    >
      <section className="work-area">
        <div className="container">
          { getSteps(steps) }
        </div>
      </section>
    </Layout>
  );
};
export default Work;
