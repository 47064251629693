import { baseURL } from 'components/config';
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from 'global_providers/TranslationProvider'; // Adjust the import path

function MenuMobile({ handleCloseMenu }) {
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const { pathname } = useLocation();
  const [current, setCurrent] = useState("home1");

  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });

  useEffect(() => {
    if (pathname) {
      let activePath = pathname.slice(1, pathname.length);
      setCurrent(activePath ? activePath : "home1");
    }
  }, [pathname]);

  const handleCurrent = useCallback(
    (param = "") => {
      setCurrent(param);
    },
    [current]
  );

  const checkActive = (param) => {
    //console.log('Current  :' + current);
    //console.log('Param  :' + param);
    if (param === "pages") {
      switch (current) {
        case "pages":
        case "login":
        case "404": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "our-service") {
      switch (current) {
        case "product-services/ec_store_mangement":
        case "product-services/cc_affiliate_marketing":
        case "product-services/warehousing":
        case "product-services/software_tech":
        case "product-details/ec_store_mangement":
        case "product-details/digital":
        case "product-details/unmaned": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "about-us") {
      switch (current) {
        case "about_pages":
        case "about":
        case "faq":
        case "job-details":
        case "job": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "news") {
      switch (current) {
        case "blog":
        case "blog-details": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "e_pages") {
      switch (current) {
        case "e_pages": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "home") {
      switch (current) {
        case "home1": {
          return true;
        }
        default:
          return false;
      }
    } else if (param === "success_story") {
      switch (current) {
        case "success_story": {
          return true;
        }
        default:
          return false;
      }
    } else {
      return false;
    }
  };

  const handleToggle = (key) => {
    //console.log('Toggle :' + key + ' : ' + toggle.key);
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  return (
    <ul className="navigation">
      <li className={`${checkActive("home") ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`}>
        <Link to="/"> { getTranslatedWords( translations , 'h_menu_home') }</Link>
      </li>

      <li className={`${checkActive("our-service") ? "active" : ""} menu-item-has-children`}>
        <h5 onClick={() => {handleToggle("our-service");}}>
          { getTranslatedWords( translations , 'h_menu_s_pages') }
        </h5>
        <Collapse isOpened={toggle.key === "our-service"}>
          <ul className="sub-menu" style={{ display: `${toggle.key === "our-service" ? "block" : "none"}` }}>
            

            <li className={`${current === "product-services/warehousing" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("product-services/warehousing");
                handleCloseMenu();
                handleToggle("our-service");
              }}
            >
              <Link to="/product-services/warehousing"> { getTranslatedWords( translations , 'h_menu_warehousing') } </Link>
            </li>


            

            <li className={`${current === "product-services/cc_affiliate_marketing" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("product-services/cc_affiliate_marketing");
                handleCloseMenu();
                handleToggle("our-service");
              }}
            >
              <Link to="/product-services/cc_affiliate_marketing"> { getTranslatedWords( translations , 'h_menu_cc_affiliate_marketing') } </Link>
            </li>

            <li className={`${current === "product-details/digital" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("product-details/digital");
                handleCloseMenu();
                handleToggle("our-service");
              }}
            >
              <Link to="/product-details/digital"> { getTranslatedWords( translations , 'h_menu_digital') }</Link>
            </li>

            <li className={`${current === "product-details/unmaned" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("product-details/unmaned");
                handleCloseMenu();
                handleToggle("our-service");
              }}
            >
              <Link to="/product-details/unmaned"> { getTranslatedWords( translations , 'h_menu_unmaned') }</Link>
            </li>


            <li className={`${current === "product-services/ec_store_mangement" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("product-services/ec_store_mangement");
                handleCloseMenu();
                handleToggle("our-service");
              }}
            >
              <Link to="/product-services/ec_store_mangement"> { getTranslatedWords( translations , 'h_menu_ec_store_mangement') }</Link>
            </li>


            <li className={`${current === "product-services/software_tech" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("product-services/software_tech");
                handleCloseMenu();
                handleToggle("our-service");
              }}
            >
              <Link to="/product-services/software_tech"> { getTranslatedWords( translations , 'h_menu_software_tech') }</Link>
            </li>


            {/*<li className={`${current === "product-details/unmaned" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("product-details/unmaned");
                handleCloseMenu();
                handleToggle("our-service");
              }}
            >
              <Link to="/product-details/unmaned"> { getTranslatedWords( translations , 'h_menu_unmaned') }</Link>
            </li>*/}

            

          </ul>
        </Collapse>

        <div className="dropdown-btn" onClick={() => {handleToggle("our-service");}}>
          <span className="fas fa-angle-down" />
        </div>
      </li>

      <li className={`${current === "blog" ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <Link to="/blog"> { getTranslatedWords( translations , 'news_title') } </Link>
      </li>

     {/* <li className={`${current === "success_story" ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <Link to="/blog"> { getTranslatedWords( translations , 'h_menu_sStory') }</Link>
      </li>*/}

      <li className={`${current === "contact" ? "active" : ""} menu-item-has-children tg-mega-menu-has-children`} onClick={handleCloseMenu}>
        <Link to="/contact"> { getTranslatedWords( translations , 'h_menu_contact') } </Link>
      </li>

      <li className={`${checkActive("about-us") ? "active" : ""} menu-item-has-children`}>
        <h5 onClick={() => {handleToggle("about-us");}}>
          { getTranslatedWords( translations , 'h_menu_about') }
        </h5>
        <Collapse isOpened={toggle.key === "about-us"}>
          <ul className="sub-menu" style={{ display: `${toggle.key === "about-us" ? "block" : "none"}` }}>
            <li className={`${current === "help" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("help");
                handleCloseMenu();
                handleToggle("about-us");
              }}
            >
              <Link to="/help"> { getTranslatedWords( translations , 'h_menu_help') }</Link>
            </li>

            <li className={`${current === "about" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("about-us");
                handleCloseMenu();
                handleToggle("about-us");
              }}
            >
              <Link to="/about"> { getTranslatedWords( translations , 'h_menu_about') } </Link>
            </li>

            <li className={`${current === "job" ? "active" : ""} menusub-link`}
              onClick={(e) => {
                e.stopPropagation();
                handleCurrent("job");
                handleCloseMenu();
                handleToggle("about-us");
              }}
            >
              <Link to="/job"> { getTranslatedWords( translations , 'h_menu_job') } </Link>
            </li>
          </ul>
        </Collapse>

        <div className="dropdown-btn" onClick={() => {handleToggle("about-us");}}>
          <span className="fas fa-angle-down" />
        </div>
      </li>
    </ul>
  );
}

export default MenuMobile;
