import { baseURL } from 'components/config';
// import BlogPost from "@/components/blog/BlogPost"
import Layout from "components/layout/Layout";
import { Link } from "react-router-dom";
import BlogList from "components/layout/blog/BlogList";
import BlogPagination from "components/layout/blog/BlogPagination";
import { useTranslation, getTranslatedWords } from '../global_providers/TranslationProvider'; // Adjust the import path

//ky change
import React, { useState, useEffect } from 'react';

const languageCode = sessionStorage.getItem('languageCode');
const article_url = baseURL + '/js/articles?is_listing=1&language=' + languageCode;


//Css class
const article_cover_photo_css = {
   height: '200px', 
   width: '100%', 
};



export default function Blog() {
  

  //ky change
     const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context

    const [activeIndex, setActiveIndex] = useState([]);
    const handleOnClick = (index) => {
      setActiveIndex(index);
    };
    
    const [articles, setArticles] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
      fetch(article_url)
         .then((response) => response.json())
         .then((data) => {
            //console.log(article_url);
            setArticles(data.articles);
            setCategories(data.categories);
            ////console.log('Result check :');
            //console.log(articles);
            //console.log(categories);
            if(data.categories.length > 0){
              setActiveIndex(categories[Object.keys(data.categories)[0]].id);
            }/*else{
              //console.log('No set');
            }*/
            
         })
         .catch((err) => {
            //console.log(err.message);
         });
    }, []);

  
    const getCategoryMenus = (categories) => {
        if(activeIndex == '')
        {
           if(categories.length > 0){
            setActiveIndex(categories[Object.keys(categories)[0]].id);
            
           }
        }

        return (
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {categories.map((category) => (
                    <li className="nav-item" onClick={() => handleOnClick(category.id)} key={category.id}>
                        <button
                            className={activeIndex === category.id ? "nav-link active" : "nav-link "}
                        >
                            {category.title}
                        </button>
                    </li>
                ))}
            </ul>
        );
    };







const getArticleList = (articles) => {
  let content = [];

  content.push(
    <>
      <div className="tab-content" id="myTabContent">
        {Object.keys(articles).map((key) => (
          <div
            key={key}
            className={`tab-pane fade ${activeIndex == key ? "show active" : "fade"}`}
          >
            <div className="blog-item-wrap">
              <div className="row justify-content-center">
                {Object.keys(articles[key]).map((child_key) => {
                  const article = articles[key][child_key];
                  const isFirstChild = child_key === "0";

                  return (
                    <div
                      className={`col-${isFirstChild ? "12" : "lg-4 md-6 sm-10"}`}
                      key={child_key}
                    >
                      <div className={`blog-${isFirstChild ? "item" : "grid-item"}`}>
                        {isFirstChild ? (
                          <>
                            <div className="blog-content">
                              <span className="date new-text">{article.publish_at}</span>
                              <h2 className="title">
                                <Link to={`/blog-details/${article.id}`}>{article.title}</Link>
                              </h2>
                              <p>{article.share_description}, </p>
                            </div>
                            <div className="blog-img">
                              <Link to={`/blog-details/${article.id}`}>
                                <img
                                  src={article.cover_photo_path}
                                  style={article_cover_photo_css}
                                  alt=""
                                  loading="lazy"
                                />
                              </Link>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="blog-grid-thumb">
                              <Link to={`/blog-details/${article.id}`}>
                                <img src={article.cover_photo_path} alt="" />
                              </Link>
                            </div>
                            <div className="blog-grid-content">
                              <span className="date new-text">{article.publish_at}</span>
                              <h2 className="title">
                                <Link to={`/blog-details/${article.id}`}>{article.title}</Link>
                              </h2>
                              <p>{article.share_description}, </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <BlogPagination />*/}
            </div>
          </div>
        ))}
      </div>
    </>
  );

  return content;
};



  return (
    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={
        <>
         <span> { getTranslatedWords( translations , 'news_title')  }</span>
        </>
      }
    >
      <section className="blog-area pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="blog-tav-wrap">
                {getCategoryMenus(categories)}
                {(getArticleList(articles))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
