import Layout from "components/layout/Layout";
import { Collapse } from "react-collapse";
import ReactHtmlParser from 'react-html-parser';
import { baseURL, contentPageURL } from 'components/config';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function Faq() {
  const { type } = useParams();
  const languageCode = sessionStorage.getItem('languageCode');
  const [contents, setContents] = useState([]);
  const [title, setTitle] = useState([]);
  const [isActive, setIsActive] = useState({
    status: false,
    key: 1,
  });

  useEffect(() => {
    const content_url = `${baseURL}/js/item-list?language=${languageCode}&type=${type}`;
    fetch(content_url)
      .then((response) => response.json())
      .then((data) => {
        //console.log(content_url);
        //console.log(data);
        setContents(data.contents);
        setTitle(data.title);
      })
      .catch((err) => {
        //console.log(err.message);
      });
  }, [type, languageCode]);

  const handleToggle = (key) => {
    if (isActive.key === key) {
      setIsActive({
        status: false,
      });
    } else {
      setIsActive({
        status: true,
        key,
      });
    }
  };

  return (
    <>
      <Layout
        headerStyle={1}
        footerStyle={1}
        breadcrumbTitle={
          <>
            {ReactHtmlParser(title.title)}
          </>
        }
      >
        <section className="faq-area pb-150">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="faq-wrap">
                  <div className="accordion">
                    {contents.map((content) => (
                      <div
                        className={
                          isActive.key === content.id
                            ? "accordion-item active"
                            : "accordion-item"
                        }
                        key={content.id}
                      >
                        <h2 className="accordion-header">
                          <button
                            className={
                              isActive.key === content.id
                                ? "accordion-button"
                                : "accordion-button collapsed"
                            }
                            type="button"
                            onClick={() => handleToggle(content.id)}
                          >
                            {content.index}. {content.title}
                          </button>
                        </h2>
                        <Collapse isOpened={isActive.key === content.id}>
                          <div
                            className={
                              isActive.key === content.id
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                            }
                          >
                            <div className="accordion-body">
                              <p> {ReactHtmlParser(content.content)}</p>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
}
