import { useState } from 'react'
import ReactHtmlParser from 'react-html-parser';
import "../../../node_modules/react-modal-video/css/modal-video.css"

export default function VideoPopup({ text }) {
    return (
        <>
            <div className="play-btn popup-video">{text ? ReactHtmlParser(text) : null}</div>
        </>
    )
}