import { baseURL } from 'components/config';
import { Link } from "react-router-dom";
import VideoPopup from "components/video/VideoPopup";

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const margin_top = {
  paddingTop: '5%',
  marginBottom: '5%', // Adjust width as needed
};

const TestimonySection = () => {
  const languageCode = sessionStorage.getItem('languageCode');
  const { id } = useParams();
  const product_service_url = `${baseURL}/js/product-service/${id}?language=${languageCode}`;

  const [activeIndex, setActiveIndex] = useState(1);
  const [testimony, setTestimony] = useState({});

  useEffect(() => {
    fetch(product_service_url)
      .then((response) => response.json())
      .then((data) => {
        //console.log('x');
        //console.log(product_service_url);
        setTestimony(data.testimony_section[0] || {});
      })
      .catch((err) => {
        ////console.log(err.message);
      });
  }, [id, languageCode]); // Add id and languageCode to the dependency array

  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className="language-area pb-150" style={margin_top}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="language-content">
              <div className="section-title-two mb-30">
                <h2 className="title">
                  {testimony.title || 'Loading...'}
                </h2>
              </div>
              <p>
                {testimony.content ? ReactHtmlParser(testimony.content) : 'Loading...'}
              </p>
              <ul className="list-wrap">
                <li>
                  <i className="fa-solid fa-comments" />
                  {testimony.content_2?.name || 'Loading...'}
                </li>
                <li>
                  <i className="fa-solid fa-list-check" />
                  {testimony.content_2?.position || 'Loading...'}
                </li>
              </ul>
            </div>
          </div>


          <div className="col-lg-6">
            <div className="language-tab-wrap">
              <div className="tab-content" id="myTabContentThree">
                <div
                  className={
                    activeIndex === 1
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src={testimony.cover_photo_path || ''} alt="" />
                    <VideoPopup text={testimony.content_2?.tagline || 'Loading...'} />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
  );
};

export default TestimonySection;
