import { useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';


// Custom hook to fetch translations
const useTranslations = (baseURL, languageCode) => {
  const [translations, setTranslations] = useState(null);
  const translation_url = `${baseURL}/js/translations?language=${languageCode}`;

  useEffect(() => {
    fetch(translation_url)
      .then((response) => response.json())
      .then((data) => {
        //console.log('Get translation');
        //console.log(data);
        setTranslations(data);
      })
      .catch((err) => {
        //console.log(err.message);
      });
  }, [translation_url]);

  return translations;
};

// Function to get translated words
export const getTranslatedWords = (translated_words, w_key) => {

  if (typeof translated_words !== 'object' || translated_words === null) {
    return 'Loading...';
  }

  return ReactHtmlParser(translated_words[w_key]) || 'Loading...';
};

export default useTranslations;
