import { baseURL } from 'components/config';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const languageCode = sessionStorage.getItem('languageCode');
const language_url = baseURL + '/js/languages?language=' + languageCode;

export default function LanguageDropdown() {
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedIcon, setSelectedIcon] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleLanguageClick = (key) => {
    const selectedLanguageData = languages[key];

    // Set cookie for language
    document.cookie = `selectedLanguage=${key}; path=/`;

    // Set session for language
    sessionStorage.setItem('selectedLanguage', key);

    // Set cookie for code
    document.cookie = `selectedCode=${selectedLanguageData.code}; path=/`;

    // Set session for code
    sessionStorage.setItem('selectedCode', selectedLanguageData.code);
    sessionStorage.setItem('languageCode', selectedLanguageData.code);

    // Set selected language and icon in state
    setSelectedLanguage(key);
    setSelectedIcon(selectedLanguageData.icon);

    // Refresh the page to apply the language change
    window.location.reload();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(language_url);
        const data = await response.json();
        setLanguages(data);

        // Retrieve selected language from cookie or session
        const cookieSelectedLanguage = document.cookie.replace(/(?:(?:^|.*;\s*)selectedLanguage\s*=\s*([^;]*).*$)|^.*$/, '$1');
        const sessionSelectedLanguage = sessionStorage.getItem('selectedLanguage');

        const selectedLanguageKey = cookieSelectedLanguage || sessionSelectedLanguage;

        // Check if the selectedLanguageKey is valid and update state accordingly
        if (selectedLanguageKey && data[selectedLanguageKey]) {
          setSelectedLanguage(selectedLanguageKey);
          setSelectedIcon(data[selectedLanguageKey].icon);

          // Update session storage to ensure consistency across tabs
          sessionStorage.setItem('selectedCode', data[selectedLanguageKey].code);
          sessionStorage.setItem('languageCode', data[selectedLanguageKey].code);
        } else {
          //console.warn('Selected Language Key is not valid or not found in the fetched data.');
        }

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // This effect ensures the icon is set correctly when languages or selectedLanguage change
    if (languages.length > 0 && selectedLanguage) {
      setSelectedIcon(languages[selectedLanguage]?.icon);
    }
  }, [languages, selectedLanguage]);

  // Check if data is still loading
  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if there was an error during fetching
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Menu as="div" className="dropdown">
        <Menu.Button
          className="dropdown-toggle show"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img src={selectedIcon} alt="" />
          {selectedLanguage ? languages[selectedLanguage].selected : 'EN'}
        </Menu.Button>
        <Menu.Items as="div" className="dropdown-menu show" aria-labelledby="dropdownMenuButton1">
          {Object.keys(languages).map((key) => (
            <Link
              key={languages[key].code}
              className={`dropdown-item ${selectedLanguage === key ? 'active' : ''}`}
              to="/"
              onClick={() => handleLanguageClick(key)}
            >
              <img src={languages[key].icon} alt="" />
              {languages[key].name}
            </Link>
          ))}
        </Menu.Items>
      </Menu>
    </>
  );
}
