import React, { createContext, useContext, useEffect, useState } from 'react';
import useTranslations, { getTranslatedWords as getTranslatedWordsHelper } from './useTranslations'; // Adjust the import path

const TranslationContext = createContext();

export const TranslationProvider = ({ children, baseURL }) => {
  const [languageCode, setLanguageCode] = useState(sessionStorage.getItem('languageCode') || 'en'); // Default to 'en'

  useEffect(() => {
    const handleStorageChange = () => {
      setLanguageCode(sessionStorage.getItem('languageCode') || 'en');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const translations = useTranslations(baseURL, languageCode);

  return (
    <TranslationContext.Provider value={{ translations, getTranslatedWords: getTranslatedWordsHelper }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  return useContext(TranslationContext);
};
