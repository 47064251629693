import { baseURL } from 'components/config';
import { Link } from "react-router-dom";
import { useState } from "react";
import VideoPopup from "components/video/VideoPopup";
const margin_top = {
       paddingTop: '5%',
       marginBottom: '5%', // Adjust width as needed
    };

const Language = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <section className="language-area pb-150" style={margin_top}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="language-content">
              <div className="section-title-two mb-30">
                <h2 className="title">
                  Our Client's Testimony 
                </h2>
              </div>
              <p>
                "Our mobile users are delighted with the improved user interface and navigation. The e-commerce assistance tools have made our platform truly mobile-friendly, resulting in increased mobile sales. Salute !!!"
              </p>
              <ul className="list-wrap">
                <li>
                  <i className="fa-solid fa-comments" />
                  Ravi Kapoor
                </li>
                <li>
                  <i className="fa-solid fa-list-check" />
                  E-commerce Strategy Manager
                </li>
                
              </ul>
              
            </div>
          </div>
          <div className="col-lg-6">
            <div className="language-tab-wrap">
              <div className="tab-content" id="myTabContentThree">
                <div
                  className={
                    activeIndex == 1
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="${baseURL}/img/testimony_cover_photo/testimony_12_11_2023_08_29_36.jpeg" alt="" />
                    <VideoPopup text="What Ravi Kapoor Say ?" />
                  </div>
                </div>
                <div
                  className={
                    activeIndex == 2
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="/assets/img/images/language_img01.jpg" alt="" />
                    <VideoPopup text="Click  Hear Spanish" />
                  </div>
                </div>
                <div
                  className={
                    activeIndex == 3
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="/assets/img/images/language_img01.jpg" alt="" />
                    <VideoPopup text="Click  Hear German" />
                  </div>
                </div>
                <div
                  className={
                    activeIndex == 4
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="/assets/img/images/language_img01.jpg" alt="" />
                    <VideoPopup text="Click  Hear French" />
                  </div>
                </div>
                <div
                  className={
                    activeIndex == 5
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="/assets/img/images/language_img01.jpg" alt="" />
                    <VideoPopup text="Click  Hear Portugal" />
                  </div>
                </div>
                <div
                  className={
                    activeIndex == 6
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="/assets/img/images/language_img01.jpg" alt="" />
                    <VideoPopup text="Click  Hear Chinese" />
                  </div>
                </div>
                <div
                  className={
                    activeIndex == 7
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="/assets/img/images/language_img01.jpg" alt="" />
                    <VideoPopup text="Click  Hear Mongolian" />
                  </div>
                </div>
                <div
                  className={
                    activeIndex == 8
                      ? "tab-pane fade show active"
                      : "tab-pane fade"
                  }
                >
                  <div className="language-img">
                    <img src="/assets/img/images/language_img01.jpg" alt="" />
                    <VideoPopup text="Click  Hear Georgian" />
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Language;
