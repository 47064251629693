import { baseURL } from 'components/config';
import { Link } from "react-router-dom";
import MenuMobile from "./MenuMobile";
//rest api
import { useCallback, useEffect, useState } from "react";
const company_url = baseURL + '/js/company';
const logo_css = {
   width: '200px', // Adjust width as needed
};

const Header4 = () => {

  //rest api
  const [company, setCompany] = useState([]);
  useEffect(() => {
    fetch(company_url)
       .then((response) => response.json())
       .then((data) => {
          console.log(data);
          setCompany(data);
       })
       .catch((err) => {
          console.log(err.message);
       });
  }, []);



  const [isMobileMenu, setMobileMenu] = useState(false);
  /*const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
    !isMobileMenu
      ? document.body.classList.add("mobile-menu-visible")
      : document.body.classList.remove("mobile-menu-visible");
  };*/

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
    !isMobileMenu
      ? document.body.classList.add("mobile-menu-visible")
      : document.body.classList.remove("mobile-menu-visible");
  };


  const getSocialMediaLinks = sms => {
      console.log(sms);
      let content = [];
      if (sms) {
        content.push(
           <div className="social-links">
                    <ul className="clearfix list-wrap">
                 {Object.keys(sms).map(key => (      
                      <li>
                        <Link to={sms[key].url}>
                          <i className={sms[key].item} />
                        </Link>
                      </li>
                  ))}
                   
                </ul>
          </div>
       
        );
      }    
    return content;
  };



  return (
    <header>
      <div
        id="sticky-header"
        className="menu-area menu-area-four transparent-header"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler" onClick={handleMobileMenu}>
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img style={logo_css} src={company.logo_photo_path} alt="Logo" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none"></div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn" onClick={handleMobileMenu}>
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo" onClick={handleMobileMenu}>
                    <Link to="/">
                      <img style={logo_css}  src={company.logo_photo_path} alt="Logo" />
                    </Link>
                  </div>
                  <div className="menu-outer">
                    <MenuMobile handleCloseMenu={handleMobileMenu} />
                  </div>


                  {getSocialMediaLinks(company.social_medias)}
                  {/*<div className="social-links">
                    <ul className="clearfix list-wrap">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-youtube" />
                        </Link>
                      </li>
                    </ul>
                  </div>
*/}


                </nav>
              </div>
              <div className="menu-backdrop" onClick={handleMobileMenu} />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header4;
