import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const floatin_bottom = {
  position:'fixed',
  bottom: '8%',
  right : '1%'
}

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div style={floatin_bottom}>
      {/* Your existing content */}
      {/* ... */}
    </div>
  );
}
