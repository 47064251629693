import { baseURL } from 'components/config';
import Slider from "react-slick";
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'global_providers/TranslationProvider'; // Adjust the import path

const languageCode = sessionStorage.getItem('languageCode');
const home_data_url = `${baseURL}/js/home-data?language=${languageCode}`;

const testimony_photo_css = {
  width: '65px', // Adjust width as needed
  height: '65px',
  marginLeft: '-5px',
  marginBottom: '3px',
};

const star_color = {
  color: '#fcc603',
};

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  autoplay: true,
  arrows: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: "0",
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        centerPadding: "0",
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerPadding: "170px",
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerPadding: "20px",
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerPadding: "0px",
      },
    },
  ],
};

const Testimonial1 = () => {
  const { translations, getTranslatedWords } = useTranslation();
  const [testimonies, setTestimonies] = useState([]);

  useEffect(() => {
    fetch(home_data_url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setTestimonies(data.testimonies || []);
      })
      .catch((err) => {
        console.error('Fetch error:', err);
      });
  }, [home_data_url]);

  const getRating = (rating) => {
    return Array.from({ length: rating }, (_, i) => (
      <i key={i} className="fas fa-star" style={star_color} />
    ));
  };

  return (
    <section className="testimonial-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="section-title text-center mb-70">
              <h2 className="title title-animation" data-wow-delay=".2s">
                {getTranslatedWords(translations, 'testimonial_crs_title')}
              </h2>
            </div>
          </div>
        </div>
        <div className="testimonial-item-wrap-two">
          <Slider {...settings} className="row testimonial-active-two">
            {testimonies.map((testimony) => (
              <div className="col-lg-4" key={testimony.id}>
                <div className="testimonial-item-two">
                  <div className="testimonial-shape-two">
                    <svg
                      viewBox="0 0 371 375"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M371 0H0V109.153C0.651939 109.052 1.31987 109 2 109C8.14716 109 13.2978 113.267 14.6521 119H20.163V122H15C15 129.18 9.1797 135 2 135C1.31987 135 0.651939 134.948 0 134.847V375H371V134.847C370.348 134.948 369.68 135 369 135C361.82 135 356 129.18 356 122H350.837V119H356.348C357.702 113.267 362.853 109 369 109C369.68 109 370.348 109.052 371 109.153V0ZM28.2283 122H36.2935V119H28.2283V122ZM44.3587 122H52.4239V119H44.3587V122ZM60.4891 122H68.5543V119H60.4891V122ZM76.6196 122H84.6848V119H76.6196V122ZM92.75 122H100.815V119H92.75V122ZM108.88 122H116.946V119H108.88V122ZM125.011 122H133.076V119H125.011V122ZM141.141 122H149.207V119H141.141V122ZM157.272 122H165.337V119H157.272V122ZM173.402 122H181.467V119H173.402V122ZM189.533 122H197.598V119H189.533V122ZM205.663 122H213.728V119H205.663V122ZM221.793 122H229.859V119H221.793V122ZM237.924 122H245.989V119H237.924V122ZM254.054 122H262.12V119H254.054V122ZM270.185 122H278.25V119H270.185V122ZM286.315 122H294.38V119H286.315V122ZM302.446 122H310.511V119H302.446V122ZM318.576 122H326.641V119H318.576V122ZM334.706 122H342.772V119H334.706V122Z"
                        fill="currentcolor"
                      />
                    </svg>
                  </div>
                  <div className="testimonial-avatar">
                    <div className="testi-avatar-img">
                      <img
                        style={testimony_photo_css}
                        src={testimony.cover_photo_path}
                        alt=""
                      />
                    </div>
                    <div className="testi-avatar-info">
                      <h5 className="title">{testimony.name}</h5>
                      <span>{testimony.position}</span>
                    </div>
                  </div>
                  <div className="testimonial-content-two">
                    <p>{testimony.content}</p>
                    <div className="rating">
                      {getRating(testimony.rating)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial1;
