import React, { useState, useEffect } from 'react';
import { baseURL } from 'components/config';
import { useTranslation } from 'global_providers/TranslationProvider'; 

const Greeting = () => {
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const inquiry_url = baseURL + '/js/customer-inquiry';
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackType, setFeedbackType] = useState(''); // 'success' or 'error'

  const label_css = {
    paddingLeft: '5px'
  };

  const title_css = {
    color: 'black'
  };

  useEffect(() => {
    const shouldShowPopup = localStorage.getItem('doNotShowAgain') !== 'true';
    setIsPopupVisible(shouldShowPopup);
  }, []);

  const handleCheckboxChange = (e) => {
    setDoNotShowAgain(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setEmailError('');
    setFeedbackMessage('');

    const data = {
      email: email,
      i_type: 'pop_up',
      doNotShowAgain: doNotShowAgain,
    };

    fetch(inquiry_url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      setFeedbackMessage(getTranslatedWords(translations, 'subscription_submission_success'));
      setFeedbackType('success');
      setTimeout(() => {
        setIsPopupVisible(false);
      }, 5000); // Close the popup after 5 seconds
    })
    .catch((error) => {
      console.error('Error:', error);
      setFeedbackMessage(getTranslatedWords(translations, 'subscription_submission_error'));
      setFeedbackType('error');
    });

    if (doNotShowAgain) {
      localStorage.setItem('doNotShowAgain', 'true');
    }
  };

  const handleClose = () => {
    setIsPopupVisible(false);
  };

  return (
    <div>
      {isPopupVisible && (
        <div className="popup">
          <div className="popup-inner">
            <button className="close-btn" onClick={handleClose}>&times;</button>
            <img src={getTranslatedWords(translations, 'greeting_pop_up_logo')} alt="Logo" className="popup-logo" />
            <h5 style={title_css}>{getTranslatedWords(translations, 'greeting_pop_up_msg')}</h5>
            <form id="contactForm" onSubmit={handleSubmit}>
                {feedbackMessage && (
                  <div
                    style={{
                      color: feedbackType === 'success' ? 'green' : 'red',
                      backgroundColor: feedbackType === 'success' ? '#d4edda' : '#f8d7da',
                      borderColor: feedbackType === 'success' ? '#c3e6cb' : '#f5c6cb',
                      padding: '10px',
                      borderRadius: '8px', // Rounded edges
                      marginBottom: '10px',
                      border: '1px solid',
                    }}
                  >
                    {feedbackMessage}
                  </div>
                )}
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={getTranslatedWords(translations, 'greeting_pop_up_enterEmail')}
                  className="email-input new-text"
                />
                {emailError && <div style={{ color: 'red' }}>{emailError}</div>}

                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={doNotShowAgain}
                    onChange={handleCheckboxChange}
                  />
                  <label style={label_css} className="new-text">{getTranslatedWords(translations, 'greeting_pop_up_doNotShowAgain')}</label>
                </div>
                <button type="submit">{getTranslatedWords(translations, 'greeting_pop_up_submit')}</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Greeting;
