import { baseURL } from 'components/config';
import { Link } from 'react-router-dom'
import Typewriter from 'typewriter-effect'
import ReactHtmlParser from 'react-html-parser';
//rest api
import React, { useState, useEffect } from 'react';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path


const languageCode = sessionStorage.getItem('languageCode');
const request_content_url = baseURL + '/js/request/home?language=' + languageCode;

const Banner1 = () => {

    const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
    const [content, setContent] = useState([]);
    const [home_title, setHomeTitle] = useState([]);

    useEffect(() => {
      fetch(request_content_url)
         .then((response) => response.json())
         .then((data) => {
            ////console.log(data);
            setContent(data);
            setHomeTitle(data.home_title[0]);
             ////console.log(data.home_title[0]);
         })
         .catch((err) => {
            ////console.log(err.message);
         });
    }, []);

   //rest api
   const [posts, setPosts] = useState([]);
   useEffect(() => {
      fetch(baseURL + '/testData')
         .then((response) => response.json())
         .then((data) => {
            ////console.log(data);
            setPosts(data);
         })
         .catch((err) => {
            ////console.log(err.message);
         });
   }, []);


    return (
        <section className="banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-content ta-animated-headline">
                                <h2 className="title ah-headline wow fadeInUp" data-wow-delay=".2s">
                                    <span> {ReactHtmlParser(home_title.title)} </span>
                                    <Typewriter tag="span"
                                        options={{
                                            wrapperClassName: "ah-words-wrapper",
                                            strings: home_title.new_hightlighted_text,
                                            autoStart: true,
                                            loop: true,
                                            delay:75,
                                            typeSpeed: 10
                                        }}
                                    />
                                </h2>
                                <h2 className="title d-none wow fadeInUp" data-wow-delay=".2s">{ReactHtmlParser(home_title.title)}  <span></span></h2>
                                <p className="wow fadeInUp new-text" data-wow-delay=".4s">{ReactHtmlParser(home_title.content)}</p>
                                <div className="banner-btn">
                                    <Link to="/blog" className="gradient-btn wow fadeInLeft" data-wow-delay=".6s"> { getTranslatedWords( translations , 'home_page_free_trial') } </Link>
                                    <Link to="/work" className="gradient-btn gradient-btn-two wow fadeInRight" data-wow-delay=".6s"> {getTranslatedWords( translations , 'home_page_hiworks') } </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

    )
}

export default Banner1;