import { useLocation } from 'react-router-dom'; // Assuming you're using React Router
const title_margin = {
       marginBottom: '-10%', // Adjust width as needed 
    };



const Breadcrumb = ({ breadcrumbTitle }) => {
  const location = useLocation();
  const isHelpPage = location.pathname === '/help';
  const titleStyle = isHelpPage ? title_margin : {};

  return (
    <section className="breadcrumb-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb-content" style={titleStyle}>
              <h2 className="title wow fadeInUp" data-wow-delay=".2s">
                {breadcrumbTitle}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
