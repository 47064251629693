import { baseURL } from 'components/config';
import LanguageDropdown from "./LanguageDropdown";
import { Link, useLocation } from "react-router-dom";
import MenuMobile from "./MenuMobile";
import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Ensure you are importing the CSS file
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path
//rest api
const languageCode = sessionStorage.getItem('languageCode');
const company_url = baseURL + '/js/company?language=' + languageCode;
const article_url = baseURL + '/js/latest/article?is_listing=1&language=' + languageCode;
const header_menu_url = baseURL + '/js/header-menu?language=' + languageCode;


const Header1 = ({ scroll, isMobileMenu, handleMobileMenu }) => {
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const { pathname } = useLocation();
  const [isActive, setIsActive] = useState("home1");

  useEffect(() => {
    if (pathname) {
      let activePath = pathname.slice(1, pathname.length);
      setIsActive(activePath ? activePath : "home1");
    }
  }, [pathname]);
  
 
  const logo_css = {
     width: '200px', // Adjust width as needed
  };

  const article_img_css = {
     height: '135px', // Adjust width as needed
  };

  //ky change
  const [articles, setArticles] = useState([]);

  //rest api
  const [company, setCompany] = useState([]);
  const [headerMenu, setHeaderMenu] = useState([]);
  useEffect(() => {
      Promise.all([
          fetch(company_url).then((response) => response.json()),
          fetch(header_menu_url).then((response) => response.json()),
          fetch(article_url).then((response) => response.json())
      ])
      .then(([companyData, headerMenuData,articleData]) => {
          setCompany(companyData);
          setArticles(articleData);
      })
      .catch((err) => {
          ////console.log(err.message);
      });
  }, []);



  const checkActive = (param) => {
    if (param === "pages") {
      return ["pages", "login", "404"].includes(isActive);
    } else if (param === "our-service") {
      return ["product-services/ec_store_mangement", "product-services/cc_affiliate_marketing", "product-services/warehousing", "product-services/software_tech", "product-details/ec_store_mangement", "product-details/digital", "product-details/unmaned"].includes(isActive);
    } else if (param === "about-us") {
      return ["about", "help", "job-details", "job"].includes(isActive);
    } else if (param === "news") {
      return ["blog", "blog-details"].includes(isActive);
    } else if (param === "e_pages") {
      return isActive === "e_pages";
    } else if (param === "home") {
      return isActive === "home1";
    } else if (param === "success_story") {
      return isActive === "success_story";
    }
    return false;
  };

  const handleActive = useCallback(
    (param = "") => {
      setIsActive(param);
    },
    [isActive]
  );

const getArticleListing = (articles) => {
    return (
      <div className="row row-cols-1 row-cols-lg-4 row-cols-xl-4">
        {Object.keys(articles).map(key => (
          <div className="col" key={`header-${articles[key].id}`}>
            <div className='mega-menu-item'>
              <div className="mega-menu-thumb">
                <Link to={`/blog-details/${articles[key].id}`}>
                  <img
                    src={articles[key].cover_photo_path}
                    alt=""
                    style={article_img_css}
                  />
                </Link>
              </div>
              <div className="mega-menu-content">
                <h4 className="title">
                  <Link to={`/blog-details/${articles[key].id}`}>{articles[key].title}</Link>
                </h4>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <header>
    <script defer src="https://use.fontawesome.com/releases/v5.15.4/js/solid.js" integrity="sha384-/BxOvRagtVDn9dJ+JGCtcofNXgQO/CCCVKdMfL115s3gOgQxWaX/tSq5V8dRgsbc" crossOrigin="anonymous"></script>
      <div
        id="sticky-header"
        className={`menu-area transparent-header ${
          scroll ? "sticky-menu" : ""
        }`}
      >
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler" onClick={handleMobileMenu}>
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img style={logo_css} src="/logo192.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li key='header-menu-home' 
                        className={`${
                          checkActive("home") ? "active" : ""
                        }`}
                        onClick={() => handleActive("home")}
                      >
                        <Link to="/"> { getTranslatedWords( translations , 'h_menu_home') } </Link>
                      </li>

                    

                      <li key='header-menu-our-service'
                        className={`${
                          checkActive("our-service") ? "active" : ""
                        } menu-item-has-children`}
                        onClick={() => handleActive("our-service")}
                      >
                        <Link to="#"> { getTranslatedWords( translations , 'h_menu_s_pages') } </Link>
                        <ul className="sub-menu">
                          


                          <li key='header-menu-product-services/warehousing'
                            className={`${isActive === "product-services/warehousing" ? "active" : ""}`}
                            onClick={() => handleActive("product-services/warehousing")}
                          >
                            <Link to="/product-services/warehousing"> { getTranslatedWords( translations , 'h_menu_warehousing') } </Link>
                          </li>

                           <li key='header-menu-product-services/cc_affiliate_marketing'
                            className={`${isActive === "product-services/cc_affiliate_marketing" ? "active" : ""}`}
                            onClick={() => handleActive("product-services/cc_affiliate_marketing")}
                          >
                            <Link to="/product-services/cc_affiliate_marketing"> { getTranslatedWords( translations , 'h_menu_cc_affiliate_marketing') } </Link>
                          </li>

                          <li key='header-menu-digital'
                            className={`${isActive === "digital" ? "active" : ""}`}
                            onClick={() => handleActive("digital")}
                          >
                            <Link to="/product-details/digital"> { getTranslatedWords( translations , 'h_menu_digital') } </Link>
                          </li>
                          

                          <li key='header-menu-product-services/ec_store_mangement'
                            className={`${isActive === "product-services/ec_store_mangement" ? "active" : ""}`}
                            onClick={() => handleActive("product-services/ec_store_mangement")}
                          >
                            <Link to="/product-services/ec_store_mangement"> { getTranslatedWords( translations , 'h_menu_ec_store_mangement') } </Link>
                          </li>

                          <li key='header-menu-product-services/software_tech'
                            className={`${isActive === "product-services/software_tech" ? "active" : ""}`}
                            onClick={() => handleActive("product-services/software_tech")}
                          >
                            <Link to="/product-services/software_tech"> { getTranslatedWords( translations , 'h_menu_software_tech') }</Link>
                          </li>

                        </ul>
                      </li>





                      <li key='header-menu-news'
                        className={`${
                          checkActive("news") ? "active" : ""
                        } menu-item-has-children tg-mega-menu-has-children`}
                        onClick={() => handleActive("news")}
                      >
                        <Link to="#"> { getTranslatedWords( translations , 'h_menu_blog') } </Link>
                        <div className="tg-mega-menu-wrap black-bg">

                        { getArticleListing(articles) }
                      

                           <div className="row row-cols-12 row-cols-lg-12 row-cols-xl-12">
                              <div className="col">
                                  <div className="mega-menu-content">
                                       <h4 className="title">
                                           <Link to="/blog"> { getTranslatedWords( translations , 'h_menu_more_news') } </Link>
                                       </h4>
                                   </div>
                              </div>
                           </div>
                        </div>
                      </li>


                      <li key='header-menu-contact'
                        className={`${isActive === "contact" ? "active" : ""}`}
                        onClick={() => handleActive("contact")}
                      >
                        <Link to="/contact"> { getTranslatedWords( translations , 'h_menu_contact') } </Link>
                      </li>

                      <li key='header-menu-about-us'
                          className={`${
                            checkActive("about-us") ? "active" : ""
                          } menu-item-has-children`}
                          onClick={() => handleActive("about-us")}
                        >
                        <Link to="#"> { getTranslatedWords( translations , 'h_menu_about_pages') } </Link>
                        <ul className="sub-menu">
                          
                          <li key='header-menu-about'
                            className={`${isActive === "about" ? "active" : ""}`}
                            onClick={() => handleActive("about")}
                          >
                            <Link to="/about"> { getTranslatedWords( translations , 'h_menu_about') } </Link>
                          </li>

                          <li key='header-menu-help'
                              className={`${isActive === "help" ? "active" : ""}`}
                              onClick={() => handleActive("help")}
                            >
                              <Link to="/help"> { getTranslatedWords( translations , 'h_menu_help') } </Link>
                          </li>

                          <li key='header-menu-job'
                            className={`${isActive === "job" ? "active" : ""}`}
                            onClick={() => handleActive("job")}
                          >
                            <Link to="/job"> { getTranslatedWords( translations , 'h_menu_job') } </Link>
                          </li>
                        </ul>
                      </li>

                    </ul>
                  </div>
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      <li className="header-lang">
                        <LanguageDropdown />
                      </li>


                      <li className="header-btn">
                        <Link to="/login" className="btn">
                          { getTranslatedWords( translations , 'h_menu_inquiry') }
                        </Link>
                      </li>


                    </ul>
                  </div>
                </nav>
              </div>
              {/* Mobile Menu  */}
              <div className="mobile-menu">
                <nav className="menu-box">
                  <div className="close-btn" onClick={handleMobileMenu}>
                    <i className="fas fa-times" />
                  </div>
                  <div className="nav-logo" onClick={handleMobileMenu}>
                    <Link to="/">
                      <img src="/logo192.png" alt="Logo" />
                    </Link>
                  </div>
                  <div className="menu-outer">
                    <MenuMobile handleCloseMenu={handleMobileMenu} />
                  </div>
                  <div className="social-links">
                    <ul className="clearfix list-wrap">
                      <li>
                        <Link to="#">
                          <i className="fab fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin-in" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-youtube" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="menu-backdrop" onClick={handleMobileMenu} />
              {/* End Mobile Menu */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header1;
