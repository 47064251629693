import { baseURL } from 'components/config';
import Layout from "components/layout/Layout";
import { Link } from "react-router-dom";

//ky change
import React, { useState, useEffect } from 'react';
import { useTranslation, getTranslatedWords } from 'global_providers/TranslationProvider'; // Adjust the import path

const languageCode = sessionStorage.getItem('languageCode');
const job_url = baseURL + '/js/jobs?language=' + languageCode;

const Job = () => {
  const { translations, getTranslatedWords } = useTranslation(); // Get the translations and function from context
  const [activeIndex, setActiveIndex] = useState(1);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  //ky change
  const [jobs, setJobs] = useState([]);
  const [categories, setCategories] = useState([]);
          useEffect(() => {
            fetch(job_url)
               .then((response) => response.json())
               .then((data) => {
                  setJobs(data.jobs);
                  setCategories(data.categories);
                  //console.log('Result check :');
                  //console.log(jobs);
                  //console.log(categories);
               })
               .catch((err) => {
                  //console.log(err.message);
               });
    }, []);

  const getCategoryMenus = categories => {
    let content = [];
    //console.log('Generate category');
    //console.log(categories);
    //console.log('Active index :' + activeIndex);
    
    content.push(
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {Object.keys(categories).map(key => (
                <li className="nav-item"  key={`category_${categories[key].id}`} onClick={() => handleOnClick(categories[key].id)}>
                  <button
                    className={
                      activeIndex == categories[key].id ? "nav-link active" : "nav-link"
                    }
                  >
                    {categories[key].title}
                  </button>
                </li>
           ))}
        </ul>
    );
    // <img src="assets/img/icon/faq_icon01.svg" alt="" />

    return content;
  };

const getJobList = jobs => {
  let content = [];
   //console.log('Loan job');
  //console.log(jobs);
  content.push(
    <div className="tab-content" id="myTabContent">
      {Object.keys(jobs).map(key => (
        <div
          key={key}
          className={
            activeIndex == key
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
        >
          <div className="job-item-wrap">
            {Object.keys(jobs[key]).map(child_key => (
              <div className="job-item" key={`category_${jobs[key][child_key].id}`}> {/* Added key */}
                <div className="job-content">
                  <h2 className="title">
                    <Link to={`/job-details/${jobs[key][child_key].id}`}>
                      {jobs[key][child_key].title}
                    </Link>
                  </h2>
                  <p className="new-text">
                    {jobs[key][child_key].share_description},{" "}
                  </p>
                  <ul className="list-wrap">
                    <li>
                      <Link to={`/job-details/${jobs[key][child_key].id}`}>
                        <i className="fas fa-map-marker-alt" />
                        {jobs[key][child_key].job_nature}
                      </Link>
                    </li>
                    <li>
                      <Link to={`/job-details/${jobs[key][child_key].id}`}>
                        <i className="fas fa-clock" />
                        {jobs[key][child_key].job_type}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="job-detail-btn">
                  <Link to={`/job-details/${jobs[key][child_key].id}`}>
                     View job
                    <i className="fas fa-arrow-right" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );

  return content;
};


  return (
    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={ <>  { getTranslatedWords( translations , 'job_list_title') } </> }>
      <section className="job-area pb-150">
        <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="job-tab-wrap">
                  {getCategoryMenus(categories)}
                  {(getJobList(jobs))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Job;
