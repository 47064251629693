import { baseURL } from 'components/config';
import Layout from 'components/layout/Layout';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'global_providers/TranslationProvider'; // Adjust the import path

const JobDetails = () => {
  const { translations, getTranslatedWords } = useTranslation();
  const { id } = useParams();
  const job_url = `${baseURL}/js/job/${id}`;
  const inquiry_url = `${baseURL}/js/customer-inquiry`;
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(''); // State to store the selected file name
  const formRef = useRef(null);

  useEffect(() => {
    fetch(job_url)
      .then((response) => response.json())
      .then((data) => {
        setJob(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [job_url]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFileName(file ? file.name : '');
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission

    // Manually check form validity
    if (formRef.current.checkValidity()) {
      // Form is valid, proceed with form submission
      const formData = new FormData(formRef.current);

      // Send data to the API
      fetch(inquiry_url, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          alert('Your inquiry has been submitted successfully.');
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('There was an error submitting your inquiry.');
        });
    } else {
      // Form is not valid, trigger native validation UI
      formRef.current.reportValidity();
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!job) {
    return <p>No job data found.</p>;
  }

  return (
    <Layout
      headerStyle={1}
      footerStyle={1}
      breadcrumbTitle={<>{getTranslatedWords(translations, 'job_title')}</>}
    >
      <section className="job-details-area pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <div className="job-details-content">
                <h2 className="title">{job.title}</h2>
                <div className="job-qualifications">
                  <p className="info-one new-text">{ReactHtmlParser(job.content)}</p>
                  <div className="job-details-list-wrap new-text">
                    <h2 className="title-two">
                      {getTranslatedWords(translations, 'job_form_title')} Job Highlights
                    </h2>
                    <ul className="list-wrap new-text">
                      <li>
                        {getTranslatedWords(translations, 'job_type')}{' '}
                        {job.job_info?.job_type}, {job.job_info?.job_nature}
                      </li>
                      <li>
                        {getTranslatedWords(translations, 'job_working_days')}{' '}
                        {job.job_info?.salary_range}
                      </li>
                      <li>
                        {getTranslatedWords(translations, 'job_working_period')}{' '}
                        {job.job_info?.salary_range}
                      </li>
                      <li>
                        {getTranslatedWords(translations, 'job_application_deadline')} October 10,
                        2023
                      </li>
                    </ul>
                  </div>

                  <div className="job-details-list-wrap">
                    <h2 className="title-two">
                      {getTranslatedWords(translations, 'job_lbl_cLevel_sRange')}
                    </h2>
                    <ul className="list-wrap new-text">
                      <li>
                        {getTranslatedWords(translations, 'job_salary_range')}{' '}
                        {job.job_info?.salary_range}
                      </li>
                    </ul>
                  </div>

                  <div className="job-details-list-wrap">
                    <h2 className="title-two">
                      {getTranslatedWords(translations, 'job_lbl_qualification_experiece')}
                    </h2>
                    <ul className="list-wrap new-text">
                      <li>
                        {getTranslatedWords(translations, 'job_experience')} {job.job_info?.experience}{' '}
                        {getTranslatedWords(translations, 'job_experience_years')}
                      </li>
                    </ul>
                  </div>

                  <div className="job-details-list-wrap">
                    <h2 className="title-two">
                      {getTranslatedWords(translations, 'job_lbl_vacancy_location')}
                    </h2>
                    <ul className="list-wrap new-text">
                      <li>
                        {getTranslatedWords(translations, 'job_slot_needed')}{' '}
                        {job.job_info?.job_nature}
                      </li>
                      <li>
                        {getTranslatedWords(translations, 'job_location')} Warsaw, Poland
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="job-details-list-wrap">
                  <h2 className="title-two">
                    {getTranslatedWords(translations, 'job_lbl_company_benefits')}
                  </h2>
                  {job.job_info?.content_2}
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="job-apply-form-wrap">
                <form ref={formRef} id="contactForm" onSubmit={handleFormSubmit} className="apply-form">
                  <input type="hidden" id="external_id" name="external_id" value={id} />
                  <input type="hidden" id="i_type" name="i_type" value="job" />
                  <h2 className="title">{getTranslatedWords(translations, 'job_form_title')}</h2>
                  <span>{getTranslatedWords(translations, 'job_form_title_desc')}</span>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-grp">
                        <label htmlFor="name">
                          {getTranslatedWords(translations, 'job_form_name')}
                        </label>
                        <input required type="text" id="name" name="name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <label htmlFor="email">
                          {getTranslatedWords(translations, 'job_form_email')}
                        </label>
                        <input required type="email" id="email" name="email" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <label htmlFor="phone">
                          {getTranslatedWords(translations, 'job_form_tel')}
                        </label>
                        <input required type="number" id="phone" name="phone" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-grp">
                        <label htmlFor="address">
                          {getTranslatedWords(translations, 'job_form_address')}
                        </label>
                        <input required type="text" id="address" name="address" />
                      </div>
                    </div>
                  </div>
                  <div className="form-grp">
                    <label htmlFor="opening">
                      {getTranslatedWords(translations, 'job_form_source')}
                    </label>
                    <input required type="text" id="opening" name="opening" />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="expected_salary">
                      {getTranslatedWords(translations, 'job_form_expected_salary')}
                    </label>
                    <input required type="text" id="expected_salary" name="expected_salary" />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="message">
                      {getTranslatedWords(translations, 'job_form_message')}
                    </label>
                    <textarea id="message" name="message" />
                  </div>
                  <div className="form-grp resume-grp">
  <label htmlFor="resume">
    {getTranslatedWords(translations, 'job_form_resume')}
  </label>
  <div className="resume-inner">
    <input
      required
      type="file"
      id="resume"
      name="resume"
      onChange={handleFileChange}
      style={{ display: 'none' }}
    />
    <label htmlFor="resume" className="resume-btn">
      Resume/CV
    </label>
    {selectedFileName && (
      <span className="file-name">{selectedFileName}</span>
    )}
  </div>
  <span>{getTranslatedWords(translations, 'job_form_resume_desc')}</span>
</div>

<style jsx>{`
  .resume-inner {
    display: flex;
    align-items: center;
  }

  .resume-btn {
    cursor: pointer;
    background-color: #007bff;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }

  .file-name {
    margin-left: 10px;
    font-size: 0.9rem;
    color: #555;
  }
`}</style>

                  <button type="submit" className="btn">
                    {getTranslatedWords(translations, 'job_form_submit')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default JobDetails;
