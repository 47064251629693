import { baseURL } from 'components/config';
import React, { useState, useEffect } from 'react';

const languageCode = sessionStorage.getItem('languageCode');
const request_content_url = `${baseURL}/js/request/home?language=${languageCode}`;

const Counter1 = () => {
  const [statistics, setStatistics] = useState([]);

  useEffect(() => {
    fetch(request_content_url)
      .then((response) => response.json())
      .then((data) => {
        setStatistics(data.statistic || []);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <section className="counter-area pt-150 pb-105">
      <div className="container">
        <div className="row justify-content-center">
          {statistics.length > 0 ? (
            Object.keys(statistics).map(key => (
              <div className="col-lg-4 col-md-6" key={`stat-${key}`}>
                <div className="counter-item">
                  <h2 className="count">{statistics[key].title}</h2>
                  <p>{statistics[key].content}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No statistics available.</p> // Fallback content if no statistics
          )}
        </div>
      </div>
    </section>
  );
};

export default Counter1;
